import Axios from 'axios';
var config = require("../../../utils/generalconfig.json");

export const GET_WC_PAYMENT_BY_SID = 'get_wc_payment_by_sid'
export const GET_WC_ORDER_PAYMENT_LIST = 'GET_WC_ORDER_PAYMENT_LIST'


let baseurl = config.api_prod_url;
if (window.location.hostname === 'admin.bookanartist.co') {
    baseurl = config.api_prod_url
} else if (window.location.hostname === 'admin.hireanartist.com.au') {
    baseurl = config.api_stage_url
} else if (window.location.hostname === 'admin.hireastreetartist.co.uk') {
    baseurl = config.api_stage_url_v2
} else if (window.location.hostname === 'localhost') {
    baseurl = config.api_local_url
}
// /////////
function handleUnAuthorised() {
    let source = window.location.pathname
    if (source !== '/' && source !== '/logout') {
        source = '?source=' + source
    } else {
        source = ''
    }
    window.location.href = '/' + source
}

export function getWescoverOrderPaymentBySid(post) {
    return dispatch => {
        return Axios.post('api/get_paid_order_details', post, { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(requestGetWescoverOrderPaymentBySid(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function requestGetWescoverOrderPaymentBySid(response) {
    return {
        type: GET_WC_PAYMENT_BY_SID,
        get_wc_payment_by_sid: response
    }
}

export function getWescoverORderBuyArtPaymentDetails(post) {
    return dispatch => {
        return Axios.post('api/get_buy_art_order_report_of_payments', post, { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(requestGetWescoverORderBuyArtPaymentDetails(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function requestGetWescoverORderBuyArtPaymentDetails(response) {
    return {
        type: GET_WC_ORDER_PAYMENT_LIST,
        get_wc_order_payment_list: response
    }
}