import { GET_WESOVER_SELLER_LIST, GET_MAKERS_DETAILS, GET_ALL_REVIEWS, UPDATE_REVIEWS, GET_SELLER_BANK_DETAILS } from '../../actions/wescoverActions/sellerActions';

function SellerReducer(state = {
    get_wesover_seller_list: [],
    get_makers_details: [],get_all_reviews :[],update_reviews:[],
    get_seller_bank_details: []
}, action) {

    switch (action.type) {
        case GET_WESOVER_SELLER_LIST:
            return Object.assign({}, state, {
                get_wesover_seller_list: action.get_wesover_seller_list,
            });
        case GET_MAKERS_DETAILS:
            return Object.assign({}, state, {
                get_makers_details: action.get_makers_details,
            });
        case GET_ALL_REVIEWS:
            return Object.assign({}, state, {
                get_all_reviews: action.get_all_reviews,
            });
        case UPDATE_REVIEWS:
            return Object.assign({}, state, {
                update_reviews: action.update_reviews,
            });
        case GET_SELLER_BANK_DETAILS:
            return Object.assign({}, state, {
                get_seller_bank_details: action.get_seller_bank_details,
            });

        default:
            return state;
    }
}

export default SellerReducer;