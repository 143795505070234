import { GET_WC_PAYMENT_BY_SID, GET_WC_ORDER_PAYMENT_LIST } from '../../actions/wescoverActions/paymentAction';

function PaymentReducer(state = {
    get_wc_payment_by_sid: [],get_wc_order_payment_list :[]
}, action) {

    switch (action.type) {
        case GET_WC_PAYMENT_BY_SID:
            return Object.assign({}, state, {
                get_wc_payment_by_sid: action.get_wc_payment_by_sid,
            });
        case GET_WC_ORDER_PAYMENT_LIST:
            return Object.assign({}, state, {
                get_wc_order_payment_list: action.get_wc_order_payment_list,
            });
        default:
            return state;
    }
}
    
export default PaymentReducer;